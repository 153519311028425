import React, { useState } from "react"
import Swiper from "react-id-swiper"
import { Link } from "gatsby"
import "react-id-swiper/lib/styles/css/swiper.css"

const params = {
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
}

function WorksSwiper(props) {
  const {
    worksSwiper,
    heading
  } = props

  const [readMore, setMore] = useState({
    slideone: false,
    slidetwo: false,
    slidethree: false,
  })

  const handleClickMore = e => {
    const identityValue = e.target.getAttribute('identity')

    setMore({
      [identityValue] : !readMore[identityValue]
    })
  }

  const handleClickSite = (site) => {
    sessionStorage.removeItem('previewSite')
    sessionStorage.setItem('previewSite', site)
    if(typeof window !== 'undefined') {
      sessionStorage.setItem('previousLink', window.location.pathname.split("/").pop())
    }
  }

  return (
    <div className="section-works-swiper">
      <div className="container">
        <h2 className="text-center">{heading}</h2>
        <div className="content-wrapper">
          <Swiper {...params}>
              {worksSwiper.map((item, i) => {
                return (
                  <div key={i} className="swiper-slide">
                    <div className="details-holder">
                      <div className="testimonial-wrapper">
                        <div className="testimonial-holder">
                          <p className="heading-2 testimonial-text">
                            {readMore[item.slideItem.toLowerCase()] ? item.comment : item.long ?item.comment.substring(0, 131) + '...' : item.comment }
                          </p>
                          <button className={item.long ? 'readmore-holder' : 'd-none'}
                            onClick={handleClickMore}
                            identity={item.slideItem.toLowerCase()}>Read {readMore[item.slideItem.toLowerCase()] ? 'less' : 'more'}
                            {readMore[item.slideItem.toLowerCase()] ? '' : <img src={require('../assets/svg/arrow.svg')} className="icon-arrow" alt="Icon Arrow" />}
                          </button>

                          <div className="testimonial-details">
                            <img src={ item.avatar } className="testimonial-image" alt="Avatar"/>
                            <div className="testimonial-customer-detail">
                              <p className="p-md testimonial-name">{item.name}</p>
                              <p className="p-md testimonial-position">{item.position}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="preview-wrapper">
                        <a href={item.link} target="_blank"  rel="noopener noreferrer nofollow">
                          <img src={item.projectImage} className="img-fluid img-holder" alt="Preview"/>
                        </a>
                        {item.external ?
                        <a href={item.link} target="_blank" className="btn-blue" rel="noopener noreferrer nofollow">Visit Website</a>
                        : <Link to="/sample-preview" className="btn-blue" rel="noopener noreferrer nofollow" onClick={() => handleClickSite(item.link)}>Visit Website</Link> }
                      </div>
                    </div>
                  </div>
                )
              })}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default WorksSwiper
