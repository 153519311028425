import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import DropdownMenu from "./DropdownMenu"
import clsx from "clsx"

function NavbarList(props) {

  const navlist = props
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false)
  const handleMouseOver = () => setOpenDropdownMenu(true)
  const deactivateMouseOver = () => setOpenDropdownMenu(false)

  function handleOpenDropdownMenu(e) {
    e.preventDefault()
    openDropdownMenu ? setOpenDropdownMenu(false) : setOpenDropdownMenu(true)
  }

  function windowWidth() {
    let windowWidth = window.innerWidth
    if (windowWidth > 991) {
      setOpenDropdownMenu(false)
    }
  }

  useEffect(() => {
    windowWidth()
    window.addEventListener('resize', windowWidth)

    return () => {
      window.removeEventListener('resize', windowWidth)
    }
  }, [])

  // console.log(navlist.dropdownContent.link)

  return (
    <li className={clsx(`navbar-nav-list list-dropdown ${navlist.customClass}`,
      {
        'show-dropdown': openDropdownMenu ,
        '' : !openDropdownMenu
      })}
      {...(!navlist.mobileChecker ? {
        onMouseEnter: handleMouseOver,
        onMouseLeave: deactivateMouseOver
      } : {})}>

      <Link
        to='/'
        className="navbar-nav-link"
        onClick={handleOpenDropdownMenu}>
        {navlist.navbarTitle}
      </Link>

      <DropdownMenu dropdownContent={navlist.dropdownContent} triggerUpdate={navlist.handleMenu}/>
    </li>
  )
}

export default NavbarList
