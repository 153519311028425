import React from "react"
import { OgTags } from "../components/OpenGraph";
import Header from "../components/Header"
import Footer from "../components/Footer"
import Brands from "../components/Brands"
import Services from "../components/Services"
import Testimonials from "../components/Testimonials"
import Plan from "../components/Plan"
import ContentImage from "../components/ContentImage"
import SampleWorksSwiper from "../components/SampleWorksSwiper"

// const logo = [
//   {
//     name: 'Taylor Clinic',
//     image: require('../assets/svg/brands-taylorclinic-logo.svg')
//   }, {
//     name: 'G24',
//     image: require('../assets/svg/brands-g24-logo.svg')
//   }, {
//     name: 'Cyber Talks',
//     image: require('../assets/svg/brands-cybertalks-logo.svg')
//   }, {
//     name: 'Unified Lawyers',
//     image: require('../assets/svg/brands-unifiedlawyers-logo.svg')
//   }, {
//     name: 'BB Clinic',
//     image: require('../assets/svg/brands-bbclinic-logo.svg')
//   }
// ]

const logoFeat = [
  {
    name: 'Gatsby',
    image: require('../assets/svg/gatsby-1.svg'),
    link: 'https://www.gatsbyjs.org/showcase/fullstackhq.com',
    activeLink: true
  }, {
    name: 'Awards',
    image: require('../assets/svg/brands-awwwards-logo.svg'),
    link: 'https://www.awwwards.com/sites/psd-wizard-1',
    activeLink: true
  }, {
    name: 'Css Design Awards',
    image: require('../assets/svg/brands-cssdesign-logo.svg'),
    link: 'https://www.cssdesignawards.com/sites/psd-wizard/32529/',
    activeLink: true
  }, {
    name: 'JAMStack',
    image: require('../assets/svg/brands-jamstack-logo.svg'),
    link: 'https://jamstack.org/examples/',
    activeLink: true
  }, {
    name: 'React Static',
    image: require('../assets/svg/brands-react-logo.svg'),
    link: 'https://github.com/react-static/react-static#sites-built-with-react-static',
    activeLink: true
  }
]

const servicesInfo = [
  {
    image: require('../assets/images/services-img7.png'),
    desc: 'Fix problems fast'
  }, {
    image: require('../assets/images/services-img8.png'),
    desc: 'Updating of graphics, text and code to save you a ton of time'
  }, {
    image: require('../assets/images/services-img9.png'),
    desc: 'Speed optimization to boost conversion and google ranking'
  }, {
    image: require('../assets/images/services-img10.png'),
    desc: 'Automated daily site backup with 1-click restore capability'
  }, {
    image: require('../assets/images/services-img11.png'),
    desc: '24/7 security monitoring to keep your website safe'
  }, {
    image: require('../assets/images/services-img12.png'),
    desc: '24/7 uptime monitoring to mitigate the risk of lost traffic'
  }, {
    image: require('../assets/images/services-img13.png'),
    desc: 'Ongoing maintenance (theme and plugin updates, cleanup)'
  }, {
    image: require('../assets/images/services-img14.png'),
    desc: 'Consultation on web development and other related matters'
  }, {
    image: require('../assets/images/services-img15.png'),
    desc: 'Monthly report of work performed'
  }
]

const plansInfo = [
  {
    image: require('../assets/images/single-task.png'),
    title: 'Maintenance',
    subtitle: 'Give it a try!',
    price: '400/mo',
    type: 'perTask',
    desc: [
      {
        listInfo: '20-hour credit balance',
        description:
        <p>
         You will be given 20-hour credits to use every month. Your credit balance will rollover
and remain available as long as your subscription remains active. Upon cancellation, these
credits will expire. If you wish to preserve your credit balance, you may keep your account under
a maintenance plan for $400/month.
        </p>
      },
      {
        listInfo: 'Development and design work',
      },
      {
        listInfo: 'Uptime monitoring',
      },
      {
        listInfo: 'Daily site backup',
      },
	  {
        listInfo: 'Daily security check',
      },
	  {
        listInfo: 'Page speed optimization',
      },
	  {
        listInfo: 'Ongoing maintenance',
		  description:
        <p>
         WordPress version upgrades, theme updates, plugin updates, bug fixes, etc.
        </p>
      },  {
        listInfo: 'Covers one site',
		  description:
        <p>
           Your maintenance subscription and the credits you receive monthly, covers one WordPress site.
        </p>
      }, 
	  {
        listInfo: 'Monthly report',
		  description:
        <p>
         Monthly report of work performed and a summary for each service.
        </p>
      },
    ],
    buttonText: 'Buy 20 hours',
	variant: 'outline',
    buttonLink: 'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-7TU50290J52525636ML2LTTA',
    buttonBeacon: false,
    buttonExternal: true,
    buttonNextTab: false,
  },
  {
    image: require('../assets/images/unlimited-task.png'),
    title: 'Full Stack',
    subtitle: 'Subscription',
    price: '720',
    range: true,
    type: 'number',
    desc: [
      {
        listInfo: 'from 40 hours credit balance',
        description:
        <p>
         Our Pay As You Go plans are considered prepaid blocks of hours. If you do not use all your hours in one month, they are rolled over to the next month.
        </p>
      },
      {
        listInfo: 'No upfront cost, 7-day trial work',
        description:
        <p>
          The 7-day trial gives you access to our battle-tested team. You decide if you want to continue after the trial.
        </p>
      },
      {
        listInfo: 'Development and design work',
      },
     {
        listInfo: 'Dedicated team',
        description:
        <p>
          Work with a project manager and a specialized developer/designer tailored to your needs.
        </p>
      },
      {
        listInfo: 'Supports multiple projects',
      },
      {
        listInfo: 'Real-time collaboration via',
        listLogo: 'true',
        listLogoImage: require('../assets/svg/slack.svg'),
        listLogoName: 'slack'
      },
      {
        listInfo: 'White-label option',
        description:
        <p>
          Branded to your agency with not a mention of Fullstack HQ anywhere.
        </p>
      },
      {
        listInfo: 'Upgrade, downgrade or cancel anytime',
        description:
        <p>
          You can upgrade or downgrade your subscription at any time by contacting us via chat or email. There is no contract for the subscription; you are free to cancel and re-subscribe whenever you want.
        </p>
      }
    ],
    buttonText: 'Learn more',
    variant: 'normal',
    buttonLink: '/development',
    buttonBeacon: false,
    buttonExternal: false,
    buttonNextTab: false,
  }
]

const testimonyList = [
  {
    text: `I would like to sincerely thank you for the excellent work you have done in developing and maintaining the G-24 website.`,
    image: require('../assets/images/shichao-zhou.png'),
    name: 'Shichao Zhou',
    position: 'G-24 Secretariat, Research Officer'
  }, {
    text: `Wanted to say I love my 2 sites. They are great. The site gets a ton of conversions. I'd say at least half my phone calls are form the website.`,
    image: require('../assets/images/troy-parsons.png'),
    name: 'Troy Parsons',
    position: 'AAR Auro Glass, Owner'
  }, {
    text: `First of all, I just want to say a big thank you for the quality and efficiency of your team's work. Pamela has been really amazing and prompt with all projects and tasks!`,
    image: require('../assets/images/renata-reis.png'),
    name: 'Renata Reis',
    position: 'collective.care, Marketing Manager'
  }
]

const worksSwiperContent = [
  {
    comment: "Since we made the changes to both landing pages, I have had consistently record sales over the last couple months. My conversions have tripled from both sites. So I just wanted to THANK YOU and tell you that do have done great work for me. Thank you!!",
    long: true,
    slideItem: 'slideOne',
    avatar: require('../assets/images/troy-parsons.png'),
    name: "Troy Parsons",
    position: "Owner, AAR Auto Glass",
    projectImage: require('../assets/images/sample-projects-19.png'),
    link: 'https://www.aarautoglass.com/',
    external: true
  },
  {
    comment: "The client (and me) are thrilled with the end result and are thankful for you and your team's great skills, communication, timeless, and quality of work!",
    long: false,
    slideItem: 'slideTwo',
    avatar: require('../assets/images/tim-haskins.png'),
    name: "Tim Haskins",
    position: "CEO, UpliftCX",
    projectImage: require('../assets/images/sample-projects-18.png'),
    link: 'https://www.segra.com/',
    external: true
  },
  {
    comment: "I would like to sincerely thank you for the excellent work you have done in developing and maintaining the G-24 website. I appreciate all the help from your team in the past two years. Thank you for your support.",
    long: true,
    slideItem: 'slideTwo',
    avatar: require('../assets/images/shichao-zhou.png'),
    name: "Shichao Zhou",
    position: "G-24 Secretariat, Research Officer",
    projectImage: require('../assets/images/sample-projects-5.png'),
    link: 'https://www.g24.org/',
    external: true
  },
  {
    comment: "First of all, I just want to say a big thank you for the quality and efficiency of your team’s work. Pamela has been really amazing and prompt with all projects and tasks!",
    long: true,
    slideItem: 'slideTwo',
    avatar: require('../assets/images/renata-reis.png'),
    name: "Renata Reis",
    position: "Marketing Manager, collective.care",
    projectImage: require('../assets/images/sample-projects-4.png'),
    link: 'https://www.taylorclinic.com.au/',
    external: true
  },
  {
    comment: "Thanks for all your sterling work on the platform - its looking really good.",
    long: false,
    slideItem: 'slideTwo',
    avatar: require('../assets/images/elizabeth-fowkes.png'),
    name: "Elizabeth Fowkes",
    position: "Marketing Executive, CyberTalks",
    projectImage: require('../assets/images/sample-projects-6.png'),
    link: 'https://cybertalks.co.uk/',
    external: true
  }
]


const cta = {
  hasBackground: false,
  hasButton: true,
  imagePrimary: true,
  title: 'Work with a battle-tested WordPress Expert',
  desc: `Focus on growing your business. Leave all your development needs to Fullstack HQ.`,
  image: require('../assets/images/banner-maintenance.png'),
  buttons: [
    {
      type: 'normal',
      text: 'Book a call',
      link: 'https://calendly.com/fullstackhq/meeting',
      beacon: false,
      external: true
    }, {
      type: 'outline',
      text: 'Chat with us',
      link: '/',
      beacon: true
    }
  ]
}

function WordPress() {
  return (
    <>
      <OgTags
        ogTitle="Outsource WordPress Developer help by Fullstack HQ"
        ogDescription="Improve your site’s functionality and rest easy with Fullstack HQ&#39;s WordPress Premium Support."
        ogUrl="https://fullstackhq.com/wordpress-help"
      />
      <Header />
      <main className="maintenance-landing initialized-header">
        <ContentImage
          content={cta}
        />
        <Testimonials
          title="Loved by world-class entrepreneurs & tech companies"
          testimony={testimonyList}
        />
        <Brands
          text="We've been featured on"
          brands={logoFeat}
        />
        <Services
          heading="What we do"
          services={servicesInfo}
        />
        <SampleWorksSwiper
          heading="Selected sample works"
          worksSwiper ={worksSwiperContent}
        />
        <Plan
          heading="Simple pricing for your WordPress needs"
          content={plansInfo}
        />
      </main>
      <Footer />
    </>
  )
}

export default WordPress
