import React from "react"
import { Link } from "gatsby"
import NavbarList from "./NavbarList"
import clsx from "clsx"

const servicePricingDropdown = [
  {
    title: 'Development',
    subtitle: 'Web & app development',
    icon: require('../assets/images/menu-development.png'),
    link: '/development',
  }, {
    title: 'Design',
    subtitle: 'Web & graphics design',
    icon: require('../assets/images/menu-design.png'),
    link: '/design',
  },
  {
    title: 'Support',
    subtitle: 'Proactive maintenance',
    icon: require('../assets/images/menu-maintenance.png'),
    link: '/support'
  }
]

const companyDropdown = [
  {
    subtitle: 'About us',
    icon: require('../assets/images/menu-about.png'),
    link: '/about'
  }, {
    subtitle: 'Customer reviews',
    icon: require('../assets/images/menu-reviews.png'),
    link: '/fullstackhq-reviews'
  }, {
    subtitle: 'Careers',
    icon: require('../assets/images/menu-careers.png'),
    link: '/careers'
  }, {
    subtitle: 'Contact us',
    icon: require('../assets/images/menu-contact.png'),
    hasBeacon: true
  }
]

function Navbar(props) {
  const navbar = props

  function handleClick() {
    if (typeof window !== "undefined") {
      if (window.Beacon) {
        window.Beacon("toggle")
      } else {
        alert("Chat helper connection timeout. Please refresh and try again.");
      }
    }
  }

  return (
    <nav className="navbar">

      <Link to="/" className="navbar-brand-link">
        <img
          src={require("../assets/svg/fullstackhq-logo.svg")}
          className="navbar-brand"
          alt= "Fullstack HQ"
        />
      </Link>

      <button
        className={clsx("hamburger hamburger--squeeze navbar-toggle", {"is-active":  navbar.isOpenMenu})}
        type="button"
        onClick={navbar.triggerUpdate}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>

      <div className={clsx({
          'navbar-collapse':true,
          'show': navbar.isOpenMenu,
          '': navbar.isOpenMenu
        })}>

        <ul className="navbar-nav navbar-nav-right unstyled">

          <NavbarList
            mobileChecker={navbar.headerIsMobile}
            dropdownContent={servicePricingDropdown}
            customClass="list-pricing"
            navbarTitle="Services &amp; Pricing"
            handleMenu={navbar.triggerUpdate}
          />

          <NavbarList
            mobileChecker={navbar.headerIsMobile}
            dropdownContent={companyDropdown}
            customClass="list-company"
            navbarTitle="Company"
            handleMenu={navbar.triggerUpdate}
          />

          <li className="navbar-nav-list">
            <span className="navbar-nav-link" onClick={handleClick}>Chat with us</span>
          </li>
          <a href="https://calendly.com/fullstackhq/meeting" target={'_blank'} className="btn btn-blue">Book a call</a>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar
