import React, { useState } from 'react'
import { Link } from 'gatsby'

const socialMedia = [
	{
		link: 'https://www.facebook.com/fullstackhq/',
		name: 'Facebook',
		icon: require('../assets/svg/social-facebook-icon.svg')
	},
	{
		link: 'https://www.instagram.com/fullstackhq/',
		name: 'Instagram',
		icon: require('../assets/svg/social-instagram-icon.svg')
	},
	{
		link: 'https://twitter.com/fullstackhq',
		name: 'Twitter',
		icon: require('../assets/svg/social-twitter-icon.svg')
	}
]

const externalLogo = [
	{
		type: 'external',
		url: 'https://www.bigcommerce.com',
		name: 'BigCommerce',
		logo: require('../assets/svg/bigcommerce-partner.svg')
	},
	{
		type: 'external',
		url: 'https://www.shopify.com/',
		name: 'Shopify',
		logo: require('../assets/svg/shopify-partners.svg')
	}
]

const footerContent = [
	{
		title: 'Services',
		child: [
			{
				type: 'internal',
				url: '/design',
				text: 'Graphic design'
			},
			{
				type: 'internal',
				url: '/development',
				text: 'Web development'
			},
			{
				type: 'internal',
				url: '/development',
				text: 'Mobile development'
			},
			{
				type: 'internal',
				url: '/wordpress-help',
				text: 'WordPress support'
			},
			{
				type: 'internal',
				url: '/bigcommerce-help',
				text: 'BigCommerce support'
			},
			{
				type: 'internal',
				url: '/shopify-help',
				text: 'Shopify support'
			}
		]
	},
	{
		title: 'Company',
		child: [
			{
				type: 'internal',
				url: '/about',
				text: 'About Fullstack HQ'
			},
			{
				type: 'internal',
				url: '/fullstackhq-reviews',
				text: 'Customer reviews',
				beacon: false,
				alert: false
			},
			{
				type: 'internal',
				url: '/careers',
				text: 'Careers',
				beacon: false,
				alert: false
			},
			{
				type: 'external',
				url: '/',
				text: 'Legal',
				beacon: true,
				alert: false
			},
			// {
			//   type: 'internal',
			//   url: 'careers',
			//   text: 'Careers',
			//   beacon: false,
			//   alert: false,
			// },
			// {
			//   type: 'external',
			//   url: 'https://psdwizard.com/blog',
			//   text: 'Blog',
			//   beacon: false,
			//   alert: false,
			// },
			{
				type: 'external',
				url: '/',
				text: 'Contact',
				alert: false,
				beacon: true
			}
		]
	},
	{
		title: 'Partners',
		child: [
			{
				type: 'external',
				url: '/',
				text: 'Affiliate programs',
				alert: true
			},
			{
				type: 'internal',
				url: '/development',
				text: 'Whitelabel for Agencies'
			},
			{
				type: 'external',
				url: '/',
				text: 'Fullstack for Nonprofits',
				alert: true
			}
		]
	}
]

function Footer() {
	const [ toggle, setToggle ] = useState({
		services: false,
		company: false,
		partners: false,
		support: false
	})

	function handleClick() {
		if (typeof window !== 'undefined') {
			if (window.Beacon) {
				window.Beacon('toggle')
			} else {
				alert('Chat helper connection timeout. Please refresh and try again.')
			}
		}
	}

	const handleToggle = (e) => {
		const name = e.target.getAttribute('name')

		setToggle({
			[name]: !toggle[name]
		})
	}

	return (
		<div className="footer">
			<div className="container">
				<div className="content-wrapper d-flex">
					<div className="footer-content">
						{/* <img src={ require('../assets/images/fs-logo.png') } className="footer-logo" alt="Fullstack HQ" /> */}
						<img
							src={require('../assets/svg/fullstackhq-logo.svg')}
							className="footer-logo"
							alt="Fullstack HQ"
						/>
						<p className="footer-details">
							Your secret weapon, extended development team. Like hiring an in-house team of designers & developers - at a fraction of the cost.
						</p>
						<ul className="footer-social-media-holder">
							{socialMedia.map((socialMedia, i) => {
								return (
									<a
										target="_blank"
										rel="noopener noreferrer nofollow"
										key={i}
										href={socialMedia.link}
										className="footer-social-media-icon"
									>
										<img src={socialMedia.icon} alt={socialMedia.name} />
									</a>
								)
							})}
						</ul>
					</div>
					<div className="footer-content-link">
						{footerContent.map((parent, i) => {
							return (
								<div key={i} className="footer-link-item">
									{parent.title !== 'Partnership' ? (
										<p
											className="footer-link-title"
											onClick={handleToggle}
											name={parent.title.toLowerCase()}
										>
											{parent.title}
											<i
												className={
													toggle[parent.title.toLowerCase()] ? (
														'fas fa-chevron-down icon-chevron rotate'
													) : (
														'fas fa-chevron-down icon-chevron'
													)
												}
											/>
										</p>
									) : null}
									<ul
										className={
											toggle[parent.title.toLowerCase()] ? (
												'footer-link-list isOpen'
											) : (
												'footer-link-list'
											)
										}
									>
										{parent.child.map((item, x) => {
											return (
												<li key={x}>
													{item.type === 'internal' ? (
														<Link to={item.url} className="footer-link">
															{item.text}
														</Link>
													) : (
														''
													)}
													{item.type === 'external' &&
													item.alert === false &&
													item.beacon === false ? (
														<a
															href={item.url}
															target="_blank"
															rel="noopener noreferrer no-follow"
															className="footer-link"
														>
															{item.text}
														</a>
													) : (
														''
													)}
													{item.type === 'external' &&
													item.alert === false &&
													item.beacon === true ? (
														<div onClick={handleClick} className="footer-link">
															{item.text}
														</div>
													) : (
														''
													)}
													{item.type === 'external' && item.alert === true ? (
														<a
															href="/"
															onClick={() => {
																alert('Coming Soon')
															}}
															className="footer-link"
														>
															{item.text}
														</a>
													) : (
														''
													)}
													{item.type === 'external' && item.hasEmail === true ? (
														<a href={`mailto:${item.email}`} className="footer-link">
															{item.text}
														</a>
													) : (
														''
													)}
												</li>
											)
										})}
									</ul>
								</div>
							)
						})}
					</div>
					<div className="external-logo-wrapper">
						{externalLogo.map((item, i) => {
							return (
								<div className="link-holder" key={i}>
									<a href={item.url} className="footer-link" target="_blank" rel="noopener noreferrer nofollow">
										<img src={item.logo} className="footer-partner" alt={item.name} />
									</a>
								</div>
							)
						})}
					</div>

					<p className="footer-text">
						Fullstack HQ Inc. is a company registered in Philippines with company no. CS201738408<br />Main Office: 468-B 2F & 3F Lead Bldg, Sto. Rosario St., Angeles City, Philippines 2009<br />Tarlac Branch: 4F MacArthur Highway, corner Don Marciano St., San Roque, Tarlac City, Philippines 2300
					</p>
				</div>
			</div>
		</div>
	)
}

export default Footer
