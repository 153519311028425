import React, { useState, useEffect } from "react"
import Navbar from "./Navbar"
import clsx from "clsx"

function Header() {
  const [activeClass, setActiveClass] = useState(false)
  const [isHeaderShown, setIsHeaderShown] = useState(true)
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [isMobile, setIsMobile] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)
  const navbarHeight = 87

  function handleOpenMenu() {
    openMenu ? setOpenMenu(false) : setOpenMenu(true)
    openMenu ? document.body.classList.remove('body-overflow') : document.body.classList.add('body-overflow')
  }

  function headerAnimate() {

    if (openMenu === true) {
      return false;
    }

    var st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
        // downscroll code
      if (window.pageYOffset > navbarHeight) {
        if(isHeaderShown) {
          setIsHeaderShown(false)
        }
      } else {
        setIsHeaderShown(true)
      }
    }
    else {
      setIsHeaderShown(true)
    }
    setLastScrollTop(st)
  }

  function scrollHandler() {
    if(window.scrollY > 51) {
      setActiveClass(true)
    } else {
      setActiveClass(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', headerAnimate)
    window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', headerAnimate)
      window.removeEventListener('scroll', scrollHandler)
    }
  })

  function windowWidth() {
    let windowWidth = window.innerWidth
    if (windowWidth < 991) {
      setIsMobile(true)

    } else {
      setIsMobile(false)
      setOpenMenu(false)
      document.body.classList.remove('body-overflow')
    }
  }

  useEffect(() => {
    windowWidth()
    window.addEventListener('resize', windowWidth)

    return () => {
      window.removeEventListener('resize', windowWidth)
      document.body.classList.remove('body-overflow')
    }
  }, [])


  return (
    <header className={clsx(
      'header',
      {
        'header-animate-show':isHeaderShown,
        'header-animate-hide':!isHeaderShown,
        'header-scroll':activeClass,
        'open-menu' : openMenu
      })}>
      <div className="container">
        <Navbar triggerUpdate={handleOpenMenu} headerIsMobile={isMobile} isOpenMenu={openMenu}/>
      </div>
    </header>
  )
}

export default Header
