import React from "react"
import { Link } from "gatsby"

function DropdownMenu(props) {

  const dropdownProps = props

  function handleClick() {
    if (typeof window !== "undefined") {
      if (window.Beacon) {
        window.Beacon("toggle")
      } else {
        alert("Chat helper connection timeout. Please refresh and try again.");
      }
    }
  }

  function updateNav() {
    if (typeof window !== "undefined") {
      if (window.innerWidth <= 991) {
        dropdownProps.triggerUpdate()
      }
    }
  }

  return (
    <div className={`dropdown-menu-holder`}>
      <div className="dropdown-menu-holder-container">
        <ul className="nav nav-vertical unstyled">
          { dropdownProps.dropdownContent.map((list, i) => {
            return (
              <li key={i} className={`nav-list ${list.hasSubpages === true ? list.customClass : ''}`}>
                {
                  list.hasBeacon === true ?
                  <div className="nav-link">
                    <div className="holder-icon">
                      <img className="nav-link-icon" src={list.icon} alt="Logo Icon"/>
                    </div>
                    <div className="nav-content">
                      <p className="nav-link-subtitle" onClick={handleClick}>{list.subtitle}</p>
                    </div>
                  </div>
                :

                list.hasSubpages === true ?
                <ul className="nav unstyled">
                  <li className="nav-link">
                    <div className="holder-icon">
                      <img className="nav-link-icon" src={list.icon} alt="Logo Icon"/>
                    </div>
                    <div className="nav-content">
                      <Link to={list.link} onClick={updateNav}>
                        <p className="nav-link-title p-lg">{list.title}</p>
                      </Link>
                      {list.subpages.map((sublist, i) => {
                        return(
                          <ul key={i} className="holder-nav-sublist unstyled">
                            <li className="nav-link-sublist">
                              <Link className="nav-link-sublink" key={i} to={sublist.link} onClick={updateNav}>
                                <p className="nav-link-subpages">{sublist.title}</p>
                              </Link>
                            </li>
                          </ul>
                        )
                      })}
                    </div>
                  </li>
                </ul>

                :

                <Link to={list.link} className="nav-link" onClick={updateNav}>
                  <div className="holder-icon">
                    <img className="nav-link-icon" src={list.icon} alt="Logo Icon"/>
                  </div>
                  <div className="nav-content">
                    <p className="nav-link-title p-lg">{list.title}</p>
                    <p className="nav-link-subtitle">{list.subtitle}</p>
                  </div>
                </Link>

              }
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default DropdownMenu
